import React from "react"
import Helmet from "react-helmet"
import Appbar from "../components/appbar"

const SuccessRate = props => (
  <div>
    <Appbar/>
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page"/>
    </Helmet>
    <div id="main" className="flex justify-center container">
      <div className=" display-3 my-5 text-green-600 p-2">
        Thanks for rating!
      </div>
    </div>
  </div>
)

export default SuccessRate
